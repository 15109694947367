/*
 * CAUTION: This file (and everything in this directory) is marked as noParse
 * in our Webpack configuration. This means it cannot import modules, and that
 * it will not be transpiled to ES5. Do *NOT* use any ES6 features that our
 * supported browsers don't support natively (i.e., IE11 does not support arrow
 * functions).
 */

(function($) {

  $.component.Component.extend('Title', 'content', {
    _needsOwnContext: true,

    _initDom: function() {
      this._super.apply(this, arguments);
      if ($.isBlank(this.$title)) {
        var tag = this._properties.tagName || 'h2';
        this.$title = this.$contents.children(tag);
        if (this.$title.length < 1) {
          this.$contents.empty().append($.tag({tagName: tag}));
          this.$title = this.$contents.children(tag);
        }
      }
    },

    _render: function() {
      var cObj = this;
      if (!cObj._super.apply(cObj, arguments)) {
        return false;
      }

      var doRender = function() {
          var t = $.isBlank(cObj._properties.text) ? '' : cObj._properties.text;
          cObj.$title.text(cObj._stringSubstitute(t));
      };
      if (!cObj._updateDataSource(cObj._properties, doRender)) {
        doRender();
      }
    },

    _propWrite: function(properties) {
      var cObj = this;
      cObj._super(properties);
      if (!_.isEmpty(properties)) {
        cObj._render();
      }
    },

    _valueKey: function() {
      return 'text';
    },

    configurationSchema: function() {
      return {
        schema: [{
          fields: [$.extend($.cf.contextPicker(), {required: false})]
        }]
      };
    },

    editFocus: function(focused) {
      if (!this._super.apply(this, arguments)) {
        return false;
      }
      if (focused) {
        return true;
      }

      $.cf.extractProperties(this.$title);
      this._updatePrimaryValue(this.$title.text());
      return true;
    },

    asString: function() {
      return this._stringSubstitute(this._properties.text);
    }
  });

})(jQuery);
