/*
 * CAUTION: This file (and everything in this directory) is marked as noParse
 * in our Webpack configuration. This means it cannot import modules, and that
 * it will not be transpiled to ES5. Do *NOT* use any ES6 features that our
 * supported browsers don't support natively (i.e., IE11 does not support arrow
 * functions).
 */

// A component that supports the display of Markdown content.
$.component.Component.extend('Formatted Text', 'content', {
  _needsOwnContext: true,

  _getAssets: function() {
    return {
      javascripts: [{ assets: 'sanitize-html' }, { assets: 'autolink-html' }, { assets: 'markdown-render' }]
    };
  },

  //Sanitizes the given HTML using a moderate whitelist, allowing tags and
  // attributes expected from Markdown rendering.
  // Keep this in sync with FormattedText in misc.rb!
  _sanitizeDisplayHtml: function(unsafeHtml) {
    return blist.util.htmlSanitizer.sanitizeHtmlPermissive(unsafeHtml);
  },

  // Renders the given Markdown document, then passes the resultant HTML
  // through a relaxed sanitizer before returning it. All HTML is removed
  // from the Markdown _before_ conversion, except for span and div tags.
  // Only class attributes are allowed for those tags.
  _safeRenderMarkdown: function(markdownDocument) {
    var cObj = this;

    //Removes all HTML from a Markdown document, except spans and divs with
    // only a class attribute (to allow for extra styling).
    // Keep this in sync with FormattedText in misc.rb!
    var safeMarkdown = blist.util.htmlSanitizer.sanitizeHtmlRestrictive(markdownDocument);

    //Render the given Markdown document into HTML.
    // Keep this in sync with FormattedText in misc.rb!
    var unsafeHtmlResult = blist.util.markdown.convertMarkdownToHtml(safeMarkdown);

    //Safe-ify the result.
    var safeHtmlResult = cObj._sanitizeDisplayHtml(unsafeHtmlResult);

    return safeHtmlResult;
  },

  _render: function() {
    if (!this._super.apply(this, arguments)) {
      return false;
    }

    var cObj = this;
    var doRender = function() {
      var substitutionTarget = cObj._properties.markdown;
      var markdown;
      var safeHtmlResult;
      var finalHtmlResult;
      if (!$.isBlank(substitutionTarget)) {
        markdown = blist.util.markdown.escapeLinksInMarkdown(cObj._stringSubstitute(substitutionTarget));
        safeHtmlResult = cObj._safeRenderMarkdown(markdown);
        finalHtmlResult = blist.util.autolinker.autolinkHtml(safeHtmlResult);
      }
      cObj.$contents.html(finalHtmlResult || '');
    };

    if (!cObj._updateDataSource(cObj._properties, doRender)) {
      doRender();
    }

    return true;
  },

  _propWrite: function(properties) {
    var cObj = this;
    cObj._super(properties);
    if (!_.isEmpty(properties)) {
      cObj._render();
    }
  },

  _valueKey: function() {
    return 'markdown';
  },

  configurationSchema: function() {
    return {
      schema: [{
        fields: [$.extend($.cf.contextPicker(), {required: false})]
      }]
    };
  },

  editFocus: function(focused) {
    if (!this._super.apply(this, arguments)) {
      return false;
    }

    if (focused) {
      return true;
    }

    $.cf.extractProperties(this.$contents);
    var contHtml = this.$contents.html();
    var sanitizedHtml = this._sanitizeDisplayHtml(contHtml);
    var markdown = '';

    // Beware HTML2Markdown will convert the entire document if its argument is falsy.
    if (sanitizedHtml) {
      markdown = HTML2Markdown(sanitizedHtml); // eslint-disable-line
    }

    this._updatePrimaryValue(markdown);
    return true;
  }
});
