/*
 * CAUTION: This file (and everything in this directory) is marked as noParse
 * in our Webpack configuration. This means it cannot import modules, and that
 * it will not be transpiled to ES5. Do *NOT* use any ES6 features that our
 * supported browsers don't support natively (i.e., IE11 does not support arrow
 * functions).
 */

$.component.Component.extend('Text', 'content', {
  _needsOwnContext: true,

  _render: function() {
    if (!this._super.apply(this, arguments)) {
      return false;
    }

    var cObj = this;
    var doRender = function() {
        var html = cObj._properties.html;
        if (!$.isBlank(html)) {
          html = cObj._stringSubstitute(html);
        }
        if (cObj._properties.isPlainText) {
          html = html.plainTextToHtml();
        }
        cObj.$contents.html(html);
    };
    if (!cObj._updateDataSource(cObj._properties, doRender)) {
      doRender();
    }

    return true;
  },

  _propWrite: function(properties) {
    var cObj = this;
    cObj._super(properties);
    if (!_.isEmpty(properties)) {
      cObj._render();
    }
  },

  _valueKey: function() {
    return 'html';
  },

  configurationSchema: function() {
    return {
      schema: [{
        fields: [$.extend($.cf.contextPicker(), {required: false})]
      }]
    };
  },

  editFocus: function(focused) {
    if (!this._super.apply(this, arguments)) {
      return false;
    }
    if (focused) {
      return true;
    }

    $.cf.extractProperties(this.$contents);
    var contHtml = $.htmlStrip(this.$contents.html());
    // Strip out straight &nbsp;, since the browser inserted that
    // (a manually entered nbsp will return as &amp;nbsp;)
    contHtml = contHtml.replace(/&nbsp;/g, ' ');
    this._updatePrimaryValue($.htmlUnescape(contHtml));
    return true;
  },
});
