/*
 * CAUTION: This file (and everything in this directory) is marked as noParse
 * in our Webpack configuration. This means it cannot import modules, and that
 * it will not be transpiled to ES5. Do *NOT* use any ES6 features that our
 * supported browsers don't support natively (i.e., IE11 does not support arrow
 * functions).
 */

(function($) {

  $.component.Picture.extend('Header', 'content', {
    _needsOwnContext: true,

    _initDom: function() {
      this._super.apply(this, arguments);
      $('body').addClass('hasHeader');
    },

    destroy: function() {
      this._super.apply(this, arguments);
        // Assume only one
        $('body').removeClass('hasHeader');
      },

      configurationSchema: function() {
        var retVal = this._super.apply(this, arguments);
        if (retVal === false) {
          return false;
        }

        retVal.schema.push({
          fields: [{
            text: $.t('dataslate.component.header.height'),
            type: 'text',
            name: 'height'
          }]
        });
        return retVal;
      }
    });

})(jQuery);
